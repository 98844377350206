import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEventImage } from '../api/connection';
import SteeringWheel from '../images/SteeringWheel.gif';
import { isTokenExpired } from '../functions/authFunctions';
import { convertUnixTimestamp } from '../functions/timestampFunctions';
import { redirectToLogin, redirectToConnectionPage } from "../functions/connectionFunctions";
import { baseURL } from "../config"

// FOR CONNECTION BUTTON and MAP DISPLAY
import { useRecoilState } from 'recoil';
import { subscribeTopicAtom, receivedMessagesAtom, eventSourceAtom } from '../recoil/atom';

const MainPage = () => {
    const navigate = useNavigate();
    const [isEventSuspicious, setIsEventSuspicious] = useState(false)
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);
    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    // For styling
    const [suspiciousHighlight, setSuspiciousHighlight] = useState(false);
    const [approvedHighlight, setApprovedHighlight] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (isTokenExpired(token)) {
            console.log("redirect to login page");
            redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
            navigate("/login");
        };
        if (!isTokenExpired(token) && subscribeTopic === '') {
            console.log("redirect to connection page");
            redirectToConnectionPage(eventSource, setReceivedMessages, setSubscribeTopic);
            navigate("/connection");
        }
    }, []);

    useEffect(() => {
        const handleWindowClose = () => {
            eventSource.close()
            navigator.sendBeacon(`${baseURL}/close_sse_connection`);
        };

        window.addEventListener("beforeunload", handleWindowClose);

        return () => {
            window.removeEventListener("beforeunload", handleWindowClose);
        };
    }, []);

    useEffect(() => {
        console.log('receivedMessages updated:', receivedMessages);
        let timer = null
        if (receivedMessages && receivedMessages[0]) {
            if (receivedMessages[0].payload.suspicious.length > 0) {
                setIsEventSuspicious(true);
                setSuspiciousHighlight(true); // Set highlight to true when a new message is received
                timer = setTimeout(() => setSuspiciousHighlight(false), 2000);

            } else {
                setIsEventSuspicious(false);
                setApprovedHighlight(true); // Set highlight to true when a new message is received
                timer = setTimeout(() => setApprovedHighlight(false), 2000);
            };
            fecthEventImage();
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        };
    }, [receivedMessages]);

    const fecthEventImage = async () => {
        console.log('fetching images');

        const userTopic = receivedMessages[0].topic
        const vehicleImageName = receivedMessages[0].payload.images.vehicle

        try {
            const response = await getEventImage(userTopic, vehicleImageName);
            
            // Handle the image blob or URL
            const imageUrl = URL.createObjectURL(await response.data);
            document.getElementById('image-container').src = imageUrl;
        } catch (error) {
            console.log(error);
        };
    };

    return (
            <nav>
                {receivedMessages.length > 0 ? (
                    <>
                        <div className="location"> Gate Location: {receivedMessages[0].payload.location.lane} </div>
                        <div className={isEventSuspicious ? `suspiciousComponent ${suspiciousHighlight ? 'suspiciousHighlightBorder' : ''}` : `approvedComponent ${approvedHighlight ? 'approvedHighlightBorder' : ''}`}>
                            {isEventSuspicious ? (
                                <div className="suspiciousHeader">
                                    <div className="suspiciousHeader1">Suspicious Vehicle Detected</div>
                                    {receivedMessages[0].payload.suspicious.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </div>
                            ) : (
                                <div className="approvedHeader">
                                    Vehicle Approved
                                </div>
                            )}
                            <div className={isEventSuspicious ? "suspiciousEvent" : "approvedEvent"}>
                                <div className="imageColumn">
                                    <img id="image-container" alt="Event Vehicle Image" />
                                </div>
                                <div className="detailColumn">
                                    <div className="date-eventId">
                                        <div className="sectionHeader">Date:</div>
                                        <div className="date-eventId-gap">{convertUnixTimestamp(receivedMessages[0].payload.event_timestamp)}</div>
                                        <div className="sectionHeaderEventId">Event ID:</div>
                                        <div className="date-eventId-gap">{receivedMessages[0].payload.event_id}</div>
                                    </div>
                                    <div className="vehicleDetails">
                                        <div className="sectionHeader">Vehicle Details:</div>
                                        <div>Manufacturer: {receivedMessages[0].payload.vehicle.manufacture}</div>
                                        <div>Type: {receivedMessages[0].payload.vehicle.category}</div>
                                        <div>Color: {receivedMessages[0].payload.vehicle.color}</div>
                                        <div>License Plate: {receivedMessages[0].payload.license_plate.text}</div>
                                    </div>
                                    <div className="devices">
                                        {receivedMessages[0].payload.vehicle_devices && (
                                            <div className="vehicleDevices">
                                                <div className="sectionHeader">Vehicle Devices:</div>
                                                    {receivedMessages[0].payload.vehicle_devices.map((vehicleDevice, index) => (
                                                        <div key={index}>{vehicleDevice.main} - {vehicleDevice.sub}</div>
                                                    ))}
                                            </div>
                                        )}
                                        {receivedMessages[0].payload.non_vehicle_devices && (
                                            <div className="nonVehicleDevices">
                                                <div className="sectionHeader">Non-Vehicle Devices:</div>
                                                    {receivedMessages[0].payload.non_vehicle_devices.map((nonVehicleDevice, index) => (
                                                        <div key={index}>{nonVehicleDevice.main} - {nonVehicleDevice.sub}</div>
                                                    ))}
                                            </div>
                                        )}
                                        {receivedMessages[0].payload.related_devices && (
                                            <div className="nonVehicleDevices">
                                                <div className="sectionHeader">Related Devices:</div>
                                                    {receivedMessages[0].payload.related_devices.map((relatedDevice, index) => (
                                                        <div key={index}>{relatedDevice.main} - {relatedDevice.sub}</div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <img src={SteeringWheel} alt="Steering Wheel" style={{height: "400px", marginTop: "40px"}} />
                        <div className="waitingText">Waiting for Cars . . .</div>
                    </div>
                )}
            </nav>

    )
}

export default MainPage;