import { atom } from "recoil";

// set topic
export const subscribeTopicAtom = atom({
    key: 'subscribeTopicAtom',
    default: '', // Initial value of the subscribed topic
});

//set event source
export const eventSourceAtom = atom({
    key: 'eventSourceAtom',
    default: {}, // Initial value of the event source
});

//received live messages
export const receivedMessagesAtom = atom({
    key: 'receivedMessagesAtom',
    default: [], // Initial value of the received message
});