import { closeSseconnection } from "../api/connection";

export const handleOnMessage = (eventSource, setReceivedMessages) => {
    if (eventSource) {
        eventSource.onmessage = function (event) {
            const message = JSON.parse(event.data);
            const payloadValue = JSON.parse(message.payload);
            const newMessage = { 'topic': message.topic, 'payload': payloadValue }

            console.log('New message received:', newMessage);

            setReceivedMessages(prevMessages => {
                // const filteredMessages = prevMessages.filter(message => message.payload.object_id !== newMessage.payload.object_id);
                // return [newMessage, ...filteredMessages];

                // Add the new message to the beginning of the array
                const updatedMessages = [newMessage, ...prevMessages];
                console.log(updatedMessages)

                // Limit the array to the most recent 10 messages
                if (updatedMessages.length > 10) {
                    updatedMessages.splice(10); // Remove the oldest message
                }

                return updatedMessages;

            });
        };
    } else {
        console.error("EventSource is not ready");
    }
};

export const handleCloseSseConnection = async (eventSource) => {
    if (eventSource) {
         if (typeof eventSource.close === "function") {
             eventSource.close(); // Closes the connection to the EventSource
             console.log("EventSource connection closed");
         };
         try {
             await closeSseconnection(); // Optional: Informs the server to clean up
         } catch (error) {
             console.error("Error during EventSource close cleanup:", error);
         };
    };
 };

export const redirectToConnectionPage = (eventSource, setReceivedMessages, setSubscribeTopic) => {
    handleCloseSseConnection(eventSource);
    setReceivedMessages([]);
    setSubscribeTopic('');
};

export const redirectToLogin = (eventSource, setSubscribeTopic, setReceivedMessages) => {
    handleCloseSseConnection(eventSource);
    localStorage.clear();
    setSubscribeTopic('');
    setReceivedMessages([]);
};