import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../../functions/authFunctions';
import GuardFLogo from '../../images/GuardFLogo.png';
import './Nav.css';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Tooltip, IconButton } from '@mui/material';
import { useRecoilState } from "recoil";
import { subscribeTopicAtom, receivedMessagesAtom, eventSourceAtom } from "../../recoil/atom";
import { redirectToLogin, redirectToConnectionPage } from "../../functions/connectionFunctions"

const Navbar = () => {

    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);

    const navigate = useNavigate();

    
    const handleLogoutUser = async () => {
        redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
        navigate("/login");
    };

    const handleBackButton = () => {
        if (user && subscribeTopic) {
            redirectToConnectionPage(eventSource, setReceivedMessages, setSubscribeTopic);
            navigate("/connection");

            // FOR WHEN THERE IS AN ADMIN AND OTHER PAGES ON THE SITE
            // navigate(-1);
        };
    };

    return (
        <nav>
        {isTokenExpired(token) ? (
            <div className="navbar_not_authorized">
                <div className="navbar_image_div">
                    <img className="navbar_image" src={GuardFLogo} alt="GuardF logo" />
                </div>
            </div>
        ) : (
            <div className="navbar_authorized">
                 <div className="navbar_icons">
                    {subscribeTopic && (
                        <Tooltip title="Back">
                            <IconButton
                                className="back"
                                variant="contained"
                                onClick={handleBackButton}
                            >
                                <ArrowBackIosIcon
                                    icon={faRightFromBracket}
                                    rotation={180}
                                    style={{ color: '#ffffff', fontSize: 'medium' }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <div className="navbar_image_div">
                    <img className="navbar_image" src={GuardFLogo} alt="GuardF logo" />
                </div>
                <div className="navbar_logout_icon">
                    <Tooltip title="Log out">
                        <IconButton
                            className="logOut"
                            variant="contained"
                            onClick={handleLogoutUser}
                        >
                            <LogoutIcon sx={{ color: "#fff" }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        )}
        </nav >
    )
}

export default Navbar;