import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, FormLabel, Typography } from '@mui/material';
import { register } from '../api/auth';
import { isValidEmail } from '../functions/authFunctions';


const RegisterPage = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const navigate = useNavigate();


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (qrCode) {
            setErrorMsg('');
        }
    }, [qrCode]);



    const registerUser = async () => {
        if (firstName !== "" && lastName !== "" && isValidEmail(email) && password !== "" && password !== confirmedPassword) {
            setErrorMsg("Passwords don't match");
            return;
        } else {
            if (firstName !== "" && lastName !== "" && isValidEmail(email) && password !== "" && password === confirmedPassword) {
                try {
                    const response = await register(firstName, lastName, email, password);
                    console.log(response)
                    localStorage.setItem("user", JSON.stringify(response.data));

                    const { qr_code } = response.data;
                    console.log(qr_code)
                    if (qr_code) {
                        setQrCode(qr_code);
                    } else {
                        setErrorMsg("QR code not available");
                    };
                } catch (error) {
                    setErrorMsg(error.response.data.error)
                    console.log(error)
                }
            } else if (firstName !== "" && lastName !== "" && !isValidEmail(email) && password !== "") {
                setErrorMsg("Please enter a valid email")
            } else {
                setErrorMsg("All fields must be filled")
            }
        };
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '100vh'
            }}
        >
            <Typography
                sx={{
                    fontSize: "25px",
                    marginBottom: "10px"
                }}
            >
                Register
            </Typography>
            <FormLabel
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <TextField
                    required
                    sx={{
                        margin: "5vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                />
                <TextField
                    required
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value) }}
                />
                <TextField
                    required
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "0vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                    id="confirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    value={confirmedPassword}
                    onChange={(e) => { setConfirmedPassword(e.target.value) }}
                />
            </FormLabel>
            <Typography
                sx={{ 
                    color: "red",
                    marginBottom: "2vh"
                }}
            >
                {errorMsg}
            </Typography>
            <Button
                variant="contained"
                disabled={qrCode}
                sx={{
                    width: "370px",
                    backgroundColor: "#0A1B3A",
                    borderRadius: "30px",
                    '&:hover': {
                        backgroundColor: "#37465E",
                    },
                }}
                onClick={() => { registerUser() }}
            >
                Next
            </Button>
            {qrCode && (
                <Box sx={{ marginTop: "2vh", textAlign: "center",  }}>
                    <Typography>To complete the registration process please scan the QR code with the Google Authenticator App</Typography>
                    <img 
                        src={qrCode}
                        alt="QR Code"
                        style={{ width: '150px', height: '150px' }}
                    />
                </Box>
            )}
            {!qrCode ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2vh"
                    }}
                >
                    <Typography>Already have an account?</Typography>
                    <Button
                        sx={{ fontWeight: "bold" }}
                        component={Link}
                        to={`/login`}
                    >
                        Sign in
                    </Button>
                </Box>
            ) : (
                <Button
                    variant="contained"
                    sx={{
                        width: "370px",
                        backgroundColor: "#0A1B3A",
                        borderRadius: "30px",
                        '&:hover': {
                            backgroundColor: "#37465E",
                        },
                    }}
                    component={Link}
                    to={`/login`}
                >
                    Finish
                </Button>
            )}
        </Box>
    )
}

export default RegisterPage;