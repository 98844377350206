import { api } from "../config"

export const getStructuredDict = (client) => api.post("/get-structured-dict", { client });

export const getEventImage = (userTopic, vehicleImageName) => 
    api.get("/get_event_image", { 
        params: {userTopic, vehicleImageName},
    responseType: 'blob'
});

export const closeSseconnection = () => api.post("/close_sse_connection", )