import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import RegisterPage from "./components/RegisterPage";
import LoginPage from "./components/LoginPage";
import ConnectionPage from "./components/ConnectionPage";
import MainPage from "./components/MainPage";


function App() {
  return (
    <div className="App">
      <Navbar />
      <main className="main">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/connection" element={<ConnectionPage />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
