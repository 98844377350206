import axios from "axios";

const inDev = false;
const inProd = false;
const inBackEnd = false;

export const baseURL = inDev
    ? "http://127.0.0.1:5013"
    : inProd
        ? "https://lprf.guardf.app"
        : "https://dev-lprf.guardf.app";


export const api = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        email: localStorage.getItem("email"),
        "Authorization": localStorage.getItem("token"),
    },
});